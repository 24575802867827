//获取场地列表
const getAreaListUrl = `/placeApi/front/reservation/placeList`;
//获取场地详情
const getAreaDetailUrl = `/placeApi/front/reservation/placeDetailInfo`;
//场地类型接口
const getAreaTypeUrl = `/placeApi/front/reservation/placeTypeList`;
//场次列表
const getSessionUrl = `/placeApi/front/reservation/placeScheduleList`;
//预约场次
const getAppointUrl = `/placeApi/front/reservation/addOrder`;
//我的预约
const myAppointUrl = `/placeApi/front/reservation/placeOrderList`;
//预约详情
const myAppointDetailUrl = `/placeApi/front/reservation/placeOrderDetail`;
//取消预约
const cancelAppointUrl = `/placeApi/front/reservation/cancelOrder`;
//我的租赁
const myrentUrl = `/spaceApi/space/getMsRentApplyList`;
//用户加入社群列表
const userTeamInfoUrl = `/placeApi/front/reservation/userTeamInfo`;
//团队积分列表
const getTeamUserPointUrl = `/placeApi/front/reservation/getTeamUserPoint`;

export {
  getAreaListUrl,
  getAreaTypeUrl,
  getAreaDetailUrl,
  getSessionUrl,
  getAppointUrl,
  myAppointUrl,
  myAppointDetailUrl,
  cancelAppointUrl,
  myrentUrl,
  userTeamInfoUrl,
  getTeamUserPointUrl
};
