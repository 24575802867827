<template>
  <div :class="['areaDetail', { isSwitchOld }]">
    <div class="activityDetail-t">
      <div class="introImg">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <div
            class="oSwiper"
            v-for="(item, index) in areaDetail.pictureBeanList"
            :key="index"
          >
            <van-swipe-item><img :src="item.url" alt="" /></van-swipe-item>
          </div>
        </van-swipe>
      </div>
      <div class="actiName">
        <div class="item">
          <div class="item-r">{{ areaDetail.name }}</div>
        </div>
      </div>
      <div class="people">
        <div class="item">
          <div class="item-l">容纳人数</div>
          <div class="item-r">{{ areaDetail.count }}人</div>
        </div>
      </div>
      <div class="address">
        <div class="item">
          <div class="item-l">活动地点</div>
          <div class="item-r">{{ areaDetail.address }}</div>
        </div>
      </div>
      <div class="address" v-if="areaDetail.contactPerson">
        <div class="item">
          <div class="item-l">场地管理员</div>
          <div class="item-r">{{ areaDetail.contactPerson }}</div>
        </div>
      </div>
      <div class="address" v-if="areaDetail.contactPhone">
        <div class="item">
          <div class="item-l">联系电话</div>
          <div class="item-r">{{ areaDetail.contactPhone }}</div>
        </div>
      </div>
    </div>
    <div class="activityDetail-c" v-if="areaDetail.equip">
      <div class="title">场地设施</div>
      <div class="body" v-html="areaDetail.equip"></div>
    </div>
    <div class="activityDetail-b" @click="toSubmit">
      <div class="btn-r">立即预约</div>
    </div>
    <v-dialog v-model="isDialog" title="请根据图文提示预约" @confirm="confirm">
      <div class="dialogInfo">
        <div class="title">{{ areaDetail.bookingDesc }}</div>
        <div class="body">
          <div
            class="item"
            v-for="(item, index) in areaDetail.bookingImgList"
            :key="index"
          >
            <img :src="item" alt="" />
          </div>
        </div>
        <div class="video">
          <video
            id="video"
            :src="areaDetail.bookingVideo"
            controls="controls"
          ></video>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { toRegister } from "@/utils/common";
import { handleImg } from "@/utils/utils";
Vue.use(Swipe);
Vue.use(SwipeItem);
import { getAreaDetailUrl } from "./api.js";
export default {
  name: "activityDetail",
  data() {
    return {
      isDialog: false,
      placeId: "",
      areaDetail: {},
      isSwitchOld: false,
    };
  },
  created() {
    if (localStorage.getItem("isSwitchOld") != null) {
      this.isSwitchOld = JSON.parse(localStorage.getItem("isSwitchOld"));
    }
    this.placeId = this.$route.query.placeId;
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
  },
  mounted() {
    this.getAreaDetail();
  },

  methods: {
    confirm() {
      this.isDialog = false;
    },
    async toSubmit() {
      if (await toRegister(this.$route.path, this.$route.query, "场馆预约")) {
        if (this.areaDetail.bookingType == 1) {
          // this.$router.push({
          //   name: "areaSession",
          //   query: {
          //     placeId: this.placeId,
          //     placeName: this.areaDetail.name,
          //     count: this.areaDetail.count,
          //   },
          // });
          this.$router.push({
            name: "apointmentContent",
            query: {
              placeId: this.placeId,
              placeName: this.areaDetail.name,
              count: this.areaDetail.count,
              supportOrderType: this.areaDetail.supportOrderType,
            },
          });
        } else if (this.areaDetail.bookingType == 2) {
          window.location.href = `tel://${this.areaDetail.bookingTel}`;
        } else if (this.areaDetail.bookingType == 3) {
          window.location.href = `${this.areaDetail.bookingLinkUrl}`;
        } else if (this.areaDetail.bookingType == 4) {
          this.isDialog = true;
        }
      }
    },
    getAreaDetail() {
      let params = {
        placeId: this.placeId,
      };

      this.$axios.get(`${getAreaDetailUrl}`, { params }).then((res) => {
        console.log(res, 5555);
        if (res.code === 200) {
          this.areaDetail = res.data;
          if (
            this.areaDetail.pictureBeanList &&
            this.areaDetail.pictureBeanList.length > 0
          ) {
            this.areaDetail.pictureBeanList.forEach((item) => {
              if (item.url && item.url.length > 0) {
                item.url = handleImg(
                  document.body.clientWidth * 2,
                  Math.ceil(((document.body.clientWidth * 2) / 16) * 9),
                  item.url
                );
              }
            });
          }
          if (this.areaDetail.bookingImg) {
            this.areaDetail.bookingImgList =
              this.areaDetail.bookingImg.split(",");
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.areaDetail {
  min-height: 100vh;
  &.isSwitchOld {
    .actiName {
      .item-r {
        font-size: 50px;
      }
    }
    .time,
    .people,
    .address {
      .item-l {
        width: 220px;
        font-size: 42px;
      }
      .item-r {
        font-size: 42px;
      }
    }
    .title {
      font-size: 44px;
    }

    .activityDetail-c {
      .body {
        font-size: 44px !important;
        span {
          font-size: 44px !important;
        }
      }
    }
    .activityDetail-b {
      .btn-r {
        font-size: 46px;
      }
    }
    .dialogInfo {
      .title {
        font-size: 42px;
      }
    }
  }
  .activityDetail-t {
    background: #fff;
  }

  .introImg {
    height: 56.25vw;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .actiName {
    padding: 32px;
    .item-r {
      font-size: 36px;
      font-weight: bold;
      color: #323334;
      line-height: 52px;
    }
  }
  .time,
  .people,
  .address {
    padding: 0 32px;
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
    }
    .item-l {
      width: 150px;
      margin-right: 32px;
      font-size: 28px;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
    }
    .item-r {
      flex: 1;
      font-size: 28px;
      font-weight: 400;
      color: #97999b;
      line-height: 40px;
    }
  }
  .title {
    position: relative;
    padding-left: 24px;
    font-size: 32px;
    font-weight: bold;
    color: #323334;
    line-height: 44px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 8px;
      height: 32px;
      background: #fe6f16;
      border-radius: 4px;
    }
  }

  .activityDetail-c {
    background: #fff;
    padding: 48px 32px 0;
    .body {
      font-size: 30px !important;
      padding: 32px 0;
      span {
        font-size: 30px !important;
      }
    }
  }
  .activityDetail-b {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #007eff;
    height: 96px;
    position: fixed;
    bottom: 0;
    left: 0;
    .btn-l {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .btn-r {
      color: #ffff;
      font-size: 32px;
      line-height: 96px;
    }
  }
  .dialogInfo {
    max-height: 800px;
    overflow-y: auto;
    padding: 20px;
    .title {
      font-size: 28px;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
    }
    .body {
      .item {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .video {
      width: 100%;
      height: 400px;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
